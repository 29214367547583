<template>
  <div class="box">
    <img src="@/assets/images/logo-transparent.png" class="img-fluid" alt="">
    <p class="text-center text-success mt-5">أدخل رقم هاتفك</p>
    <div class="input">
      <input type="text" placeholder="+964" class="game_input" v-model="phone" @input="valueChanged" />
    </div>

    <div class="mt-4 butn">
      <button :class="{ game_btn: true, activeBtn: isChanged }" id="sub_btn" :disabled="btnDisabled" style="direction: rtl"
        @click="sendSMS()">{{ btnDisabled ? "تحميل.... " : "تابع" }}</button>
    </div>

    <div class="desc mt-2">
      <p class="text-center text-success" style="direction: rtl;font-size: 16px">
        مرحبًا بك في FitVibe! انغمس في عالم من مقاطع الفيديو التدريبية الحصرية ونصائح النظام الغذائي المخصصة لتعزيز رحلة لياقتك البدنية. لمشتركي زين، سيتم استقطاع 240 د.ع يوميا للرسالة المستلمة. يمكنك إلغاء الإشتراك في أي وقت كان من خلال إرسال F7 إلى 3368.
      </p>
    </div>
  </div>
</template>

<script>
import AlertService from '@/services/errors'
import ApiService from '@/services/api'
import { v4 as uuidv4 } from "uuid"
// import { animation } from '@/config/pluginInit'

export default {
  data () {
    return {
      prefixPHone: '+964',
      phone: '+964',
      isChanged: false,
      btnDisabled: false
    }
  },
  methods: {
    valueChanged (Val) {
      this.isChanged = true
    },
    async generateScript () {
      this.btnDisabled = true
      var phoneNumber = '964' + this.phone.replaceAll('+964', '')
      if (phoneNumber !== '9641231234') {
        AlertService.errorMessage("Invalid Phone")
        this.btnDisabled = false
        return
      }
      var uuid = "Game-Expert-App-" + uuidv4()
      var timestamp = this.getTimeStamp()
      this.$store.commit('setPhoneNumber', phoneNumber)
      this.$store.commit('setTi', uuid)
      this.$store.commit('setTS', timestamp)
      const response = await ApiService.generateScript(
        uuid,
        timestamp
      )
      if (response && response.status === 200) {
        var s = response.data.s
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.text = s
        document.head.appendChild(script)
        const ev = new Event('DCBProtectRun')
        document.dispatchEvent(ev)
        await this.sendSMS()
      } else {
        this.btnDisabled = false
      }
    },

    async sendSMS () {
      this.btnDisabled = true
      var phoneNumber = '964' + this.phone.replaceAll('+964', '')

      // const phoneNumber = this.$store.state.phoneNumber
      const ti = this.$store.state.ti
      await ApiService.login(phoneNumber, ti).then((response) => {
        if (response.data.data && response.data.code === 22) {
          this.$router.push(`/`)
        } else if (response.data.data && (response.data.code === 0 || response.data.code === 123)) {
          this.$router.push(`/verify/${phoneNumber}/${response.data?.data?.referenceId}`)
        } else {
          this.errorMessage('Error')
        }
        this.btnDisabled = false
      })
    },
    getTimeStamp () {
      return parseInt(new Date().getTime() / 1000)
    }
  },
  mounted () {
    this.$i18n.locale = localStorage.getItem("lang") ?? "ar"
  }
}
</script>

<style>

img{
  width: 100%;
  height: 100%;
}

</style>
